import React from "react";
import Footertop from "./Footertop";
import "./comp.css";

const Footer = () => {
  return (
    <>
      <Footertop />
      <div className="container text-center pt-3 pb-5">
        <div className="row">
          <span className="footerspan">
            Copyright @2024 Powered by brandvo.in
          </span>
          {/* <span className="footerspan">
            Powered by brandvo.in
          </span> */}
          {/* <div className="col-sm-12">
            <ul className="footerul">
              <li>Resources</li>
              <li>Vandors</li>
              <li>CP Venues</li>
              <li>CP Venues</li>
              <li>Tutorials</li>
              <li>Jobs</li>
              <li>Contact</li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
