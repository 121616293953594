import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const PreWeddingComp = () => {
  const images = [
    {
      original: "/images/Copy of 138A8533.JPG",
      thumbnail: "/images/Copy of 138A8533.JPG",
    },
    {
      original: "/images/Copy of 138A8336.JPG",
      thumbnail: "/images/Copy of 138A8336.JPG",
    },
    {
      original: "/images/Copy of 138A8254.JPG",
      thumbnail: "/images/Copy of 138A8254.JPG",
    },
    {
      original: "/images/Copy of 138A7479.JPG",
      thumbnail: "/images/Copy of 138A7479.JPG",
    },
    {
      original: "/images/138A7445.JPG",
      thumbnail: "/images/138A7445.JPG",
    },
    {
      original: "/images/138A7567.JPG",
      thumbnail: "/images/138A7567.JPG",
    },
    {
      original: "/images/138A7586.JPG",
      thumbnail: "/images/138A7586.JPG",
    },
    {
      original: "/images/138A7402.JPG",
      thumbnail: "/images/138A7402.JPG",
    },
    {
      original: "/images/138A7728.JPG",
      thumbnail: "/images/138A7728.JPG",
    },
    {
      original: "/images/138A7878.JPG",
      thumbnail: "/images/138A7878.JPG",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreWeddingComp;
