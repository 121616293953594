import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/Logo-modified.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import { useAuth } from "../context/auth";
import CustomModal from "../components/CustomModal";


const Header = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "+919627903849";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const [auth, setAuth] = useAuth();
  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };

  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid mainnnav">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <NavLink className="navbar-brand" to="/">
                  <img
                    style={{
                      height: "60px",
                      width: "120px",
                      padding: "0",
                      margin: "0",
                    }}
                    src={logo}
                    alt="logo"
                  />
                </NavLink>
                <div>
                  <h2
                    style={{
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                      color: "#dc3545",
                      fontFamily: "cursive",
                    }}
                  >
                    Choice{" "}
                    <span
                      style={{
                        background: "#198754",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "14px",
                        padding: "3px",
                      }}
                    >
                      Production
                    </span>{" "}
                  </h2>
                </div>
              </div>

              <div className="text-center" style={{ alignItems: "center" }}>
                <p className="address" style={{ fontSize: "14px" }}>
                  A 89, Shivalik Colony, Malviya Nagar <br /> New Delhi, Delhi
                  (110017)
                </p>
              </div>
              <div>
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/"
                      style={{ fontSize: "14px" }}
                    >
                      Phone: +91 96279 03849
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/contactus"
                      style={{ fontSize: "14px" }}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="#"
                    style={{ fontSize: "14px" }}
                  >
                   <CustomModal/>
                  </NavLink>
                </li>
            
                  {/* {!auth.user ? (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/login"
                    style={{ fontSize: "14px" }}
                  >
                    Login
                  </NavLink>
                </li>):(
                   <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {auth?.user?.name}
                </NavLink>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink
                      to={`/dashboard/${
                        auth?.user?.role === 1 ? "admin" : "user"
                      }`}
                      className="dropdown-item"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/login"
                      className="dropdown-item"
                      onClick={logout}
                    >
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </li>
                )} */}
                  <li className="nav-item mx-2">
                    <NavLink
                      className="nav-link"
                      to="/"
                      style={{
                        backgroundColor: "lightgreen",
                        color: "white",
                        height: "30px",
                        width: "30px",
                        fontSize: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                      }}
                      onClick={handleWhatsAppClick}
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            ></div>
          </div>
        </nav>
        <div className="container-fluid">
          <div className="row pt-3">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="col-sm-12"
            >
              <ul className="headul">
                <li>
                  <NavLink className="navli" to="/">
                    HOME
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navli" to="/ourstory/OurPhotographyteam">
                    OUR STORY
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navli" to="/weddingphotography">
                    WEDDING PHOTOGRAPHY
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navli" to="/prewedding">
                    PRE-WEDDING
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navli" to="/albums-flushmount">
                    ALBUMS
                  </NavLink>
                </li>
                <li className="dropp">
                  <NavLink className="navli" to="#">
                    WEDDING VENUES <FontAwesomeIcon icon={faCaretDown} />
                  </NavLink>
                  <div className="container-fluid p-3 drop">
                    <ul className="venue-ul">
                      <li>
                        <NavLink className="navli" to="/weddingvenues/Jaipur">
                          Jaipur
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navli" to="/weddingvenues/Udaipur">
                          Udaipur
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navli" to="/NeemranaFort">
                          Neemrana Fort
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
