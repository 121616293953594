import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import ModalBootstrap from "react-bootstrap/Modal";
import emailjs from "emailjs-com"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomModal = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    phone: "", 
    message: "",
  });

  const form = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_scyi38r",
        "template_7wlmlkc",
        form.current,
        "yL0PjFNI5nABXDT-W"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          setFormData({
            user_name: "",
            user_email: "",
            phone: "",
            message: "",
          });
          toast.success("Thank you!!!")
          handleClose(); 

        },
        (error) => {
          console.log("FAILED...", error.text);
          toast.error("failed to data")
        }
      );
  };

  return (
    <>
    <ToastContainer />
      <Button variant="primary" onClick={handleShow} style={{fontSize:'12px'}}>
        ENQUIRY NOW
      </Button>
      <ModalBootstrap show={show} onHide={handleClose}>
      <ModalBootstrap.Header closeButton>
          <img src="/images/Logo.jpg" alt="Logo" style={{ maxWidth: '100px' }} />
          <ModalBootstrap.Title>
            <h2 style={{ fontSize: '25px', display: 'flex', alignItems: 'center', color: '#dc3545', fontFamily: 'cursive' }}>
              Choice{' '}
              <span style={{ background: '#198754', color: '#fff', borderRadius: '5px', fontSize: '14px', padding: '3px' }}>
                Production
              </span>
            </h2>
          </ModalBootstrap.Title>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="user_name"
                value={formData.user_name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="user_email"
                value={formData.user_email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="contact">Contact</label>
              <input
                type="text"
                className="form-control"
                id="contact"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your contact number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
              ></textarea>
            </div>
          </form>
        </ModalBootstrap.Body>
        <ModalBootstrap.Footer>
          <Button onClick={sendEmail} style={{ background: "#dc3545" }}>
            Submit
          </Button>
        </ModalBootstrap.Footer>
      </ModalBootstrap>
    </>
  );
};

export default CustomModal;
