import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const NeemranaFortComp = () => {
  const images = [
    {
        original: "/images/YSH_2969.JPG",
        thumbnail: "/images/YSH_2969.JPG",
      },
    {
      original: "/images/YSH_3764.jpg",
      thumbnail: "/images/YSH_3764.jpg",
    },
    {
      original: "/images/YSH_3728.jpg",
      thumbnail: "/images/YSH_3728.jpg",
    },
    {
      original: "/images/YSH_3683.jpg",
      thumbnail: "/images/YSH_3683.jpg",
    },
    {
      original: "/images/YSH_3690.jpg",
      thumbnail: "/images/YSH_3690.jpg",
    },
    {
      original: "/images/YSH_3709.JPG",
      thumbnail: "/images/YSH_3709.JPG",
    },
    {
      original: "/images/YSH_3055.JPG",
      thumbnail: "/images/YSH_3055.JPG",
    },
    {
      original: "/images/YSH_3059.JPG",
      thumbnail: "/images/YSH_3059.JPG",
    },
  
    {
      original: "/images/YSH_2941.JPG",
      thumbnail: "/images/YSH_2941.JPG",
    },
    {
      original: "/images/YSH_3757.JPG",
      thumbnail: "/images/YSH_3757.JPG",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NeemranaFortComp;
