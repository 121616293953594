import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/Logo-modified.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCaretDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import CustomModal from "../components/CustomModal";

const Header2 = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "+919627903849";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <nav
      style={{ position: "relative" }}
      className="navbar navbar-expand-lg bg-body-tertiary"
    >
      <div className="container-fluid">
        <NavLink style={{ fontWeight: "400" }} className="navbar-brand" to="/">
          <img
            style={{ height: "65px", width: "138px" }}
            src={logo}
            alt="logo"
          />
          <div>
            <h2
              style={{
                fontSize: "25px",
                color: "#dc3545",
                fontFamily: "cursive",
              }}
            >
              Choice{" "}
              <span
                style={{
                  background: "#198754",
                  color: "#fff",
                  borderRadius: "5px",
                  fontSize: "17px",
                  padding: "3px",
                }}
              >
                Production
              </span>
            </h2>
          </div>
          <div>
            <p
              style={{
                textAlign: "center",
                padding: "20px",
                whiteSpace: "wrap",
              }}
            >
              A 89, Shivalik Colony, Malviya Nagar, New Delhi, Delhi (110017)
            </p>
          </div>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item my-0">
              <NavLink
                style={{ fontWeight: "400", fontSize: "16px" }}
                className="nav-link active"
                aria-current="page"
                to="/"
              >
                24x7 Support : +91 96279 03849
              </NavLink>
            </li>
            <li className="nav-item my-0">
              <NavLink
                style={{ color: "red", fontWeight: "400", fontSize: "16px" }}
                className="nav-link active"
                aria-current="page"
                to="/"
              >
                Contact Us <FontAwesomeIcon icon={faAngleRight} />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="#" style={{ fontSize: "14px" }}>
                <CustomModal />
              </NavLink>
            </li>
            <li className="nav-item my-0">
              <NavLink
                style={{
                  backgroundColor: "lightgreen",
                  color: "white",
                  height: "30px",
                  width: "30px",
                  fontSize: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                }}
                className="nav-link active"
                aria-current="page"
                to="/"
                onClick={handleWhatsAppClick}
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </NavLink>
            </li>
            <ul className="headul2">
              <li>
                <NavLink className="navli" to="/">
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink className="navli" to="/ourstory/OurPhotographyteam">
                  OUR STORY
                </NavLink>
              </li>
              <li>
                <NavLink className="navli" to="/weddingphotography">
                  WEDDING PHOTOGRAPHY
                </NavLink>
              </li>
              <li>
                <NavLink className="navli" to="/prewedding">
                  PRE-WEDDING
                </NavLink>
              </li>
              <li>
                <NavLink className="navli" to="/albums-flushmount">
                  ALBUMS
                </NavLink>
              </li>
              <li className="navli dropp">
                <NavLink className="navli" to="#">
                  WEDDING VENUES <FontAwesomeIcon icon={faCaretDown} />
                </NavLink>
                <div className="container-fluid p-3 drop">
                  <ul className="venue-ul">
                    <li>
                      <NavLink className="navli" to="/weddingvenues/Jaipur">
                        Jaipur
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="navli" to="/weddingvenues/Udaipur">
                        Udaipur
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="navli"
                        to="/weddingvenues/NeemranaFort"
                      >
                        Neemrana Fort
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header2;
