import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const FiveComp = () => {
  const images = [
    {
      original: "/images/IMG_4041.JPG",
      thumbnail: "/images/IMG_4041.JPG",
    },
    {
      original: "/images/IMG_4161.JPG",
      thumbnail: "/images/IMG_4161.JPG",
    },
    {
      original: "/images/IMG_4192.JPG",
      thumbnail: "/images/IMG_4192.JPG",
    },
    {
      original: "/images/IMG_4038.JPG",
      thumbnail: "/images/IMG_4038.JPG",
    },
    {
      original: "/images/IMG_3945.JPG",
      thumbnail: "/images/IMG_3945.JPG",
    },
    {
      original: "/images/IMG_3951.JPG",
      thumbnail: "/images/IMG_3951.JPG",
    },
    {
      original: "/images/IMG_3952.JPG",
      thumbnail: "/images/IMG_3952.JPG",
    },
    {
      original: "/images/IMG_3868.JPG",
      thumbnail: "/images/IMG_3868.JPG",
    },
    {
      original: "/images/IMG_3867.JPG",
      thumbnail: "/images/IMG_3867.JPG",
    },
    {
      original: "/images/IMG_3888.JPG",
      thumbnail: "/images/IMG_3888.JPG",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 p-4">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FiveComp;
