import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(5);
  const Navigate = useNavigate();
  const Location = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevvalue) => --prevvalue);
    }, 1000);
    if (count === 0) {
      Navigate(`/${path}`, {
        state: Location.pathname,
      });
    }
    return () => clearInterval(interval);
  }, [count, Navigate, Location, path]);
  return (
    <>
      <h1 className="text-center">
        Redirecting you to login page in {count} seconds
      </h1>
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Spinner;
