import React from "react";
import { Link } from "react-router-dom";
const Footercontact = () => {
  return (
    <div
      style={{ backgroundColor: "rgb(115,153,115)" }}
      className="container-fluid py-3"
    >
      <div className="row pt-3">
        <div
          style={{ fontSize: "30px", color: "white" }}
          className="col-sm-12 text-center my-3"
        >
          READY TO GET STARTED?
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-sm-12 text-center my-3">
        <Link to="/contactus" style={{ textDecoration: 'none' }}>
      <button
        style={{
          backgroundColor: 'transparent',
          color: 'white',
          fontWeight: 'bold',
          border: '1px solid white',
          padding: '13px 30px',
          cursor: 'pointer', // Add cursor pointer for better UX
        }}
      >
        CONTACT US
      </button>
    </Link>
        </div>
      </div>
    </div>
  );
};

export default Footercontact;
