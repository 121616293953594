import React, { useState, useEffect } from "react";
import ContactFix from "../../components/ContactFix";
import Footercontact from "../../layout/footercontact";
import Layout from "../../layout/Layout";
import SixComp from "../../slideComp/SixComp";

const NeemranaFort = () => {
  const style1 = {
    backgroundImage: `url("https://www.holidify.com/blog/wp-content/uploads/2014/09/Entertainment-at-the-amphit_copy.jpg")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "90vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <>
      <ContactFix />
      <Layout title={"ChoiceProduction-WeddingVenues-NeemranaFort"}>
      <div className="container-fluid" style={style1}>
        <div
          className="row"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-sm-12">
            <h3
              style={{
                fontSize: "5vw",
                fontFamily: "Arapey, serif",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Neemrana Fort Wedding Shoot
            </h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h2 style={{ textAlign: "center" }}>
                Neemrana Fort - Just A Stone Throw Away From Delhi:
              </h2>
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                This ancient fort near Delhi is perfect for a photo-shoot owing
                to its imposing and incredible architecture and the
                shock-and-awe effect that many placesdon'tt boast of. The
                Neemrana Fort Palace has become very popular over the years as a
                weekend getaway from Delhi to bring about a royal and marvellous
                nuptial ceremony and present the bride and the groom with some
                cherished moments (and photographs). This place in India is the
                ideal ingredient for a Destination wedding to remember.{" "}
              </p>
            </div>
            <div>
              <h2 className="text-center">SAMIR & SHRUTI WEDDING VIDEOGRAPHY</h2>
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/4P05oi0lj-o?si=FWkoVnFSxmFyNLov" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <SixComp/>
      <Footercontact />
      </Layout>
    </>
  );
};

export default NeemranaFort;
