import React from "react";
import { Link } from "react-router-dom";

const ContactFix = () => {
  return (
    <>
      <div className="sideContact">
        <button
          style={{
            background: "rgb(154, 204, 154)",
            border: "none",
            // height: "8vh",
            // width: "15vw",
          }}
        >
          <Link to="/contactus" style={{textDecoration:'none', color:'#fff'}}>Contact Us Today</Link>
        </button>
      </div>
    </>
  );
};

export default ContactFix;
