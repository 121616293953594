import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const SixComp = () => {
  const images = [
    {
      original: "/images/IMG_3728.JPG",
      thumbnail: "/images/IMG_3728.JPG",
    },
    {
      original: "/images/IMG_3489.JPG",
      thumbnail: "/images/IMG_3489.JPG",
    },
    {
      original: "/images/IMG_3773.JPG",
      thumbnail: "/images/IMG_3773.JPG",
    },
    {
      original: "/images/IMG_3816.JPG",
      thumbnail: "/images/IMG_3816.JPG",
    },
    {
      original: "/images/IMG_3753.JPG",
      thumbnail: "/images/IMG_3753.JPG",
    },
    {
      original: "/images/IMG_3854.JPG",
      thumbnail: "/images/IMG_3854.JPG",
    },
    {
      original: "/images/IMG_3945.JPG",
      thumbnail: "/images/IMG_3945.JPG",
    },
    {
      original: "/images/IMG_3816.JPG",
      thumbnail: "/images/IMG_3816.JPG",
    },
    {
      original: "/images/IMG_4192.JPG",
      thumbnail: "/images/IMG_4192.JPG",
    },
    {
      original: "/images/IMG_4161.JPG",
      thumbnail: "/images/IMG_4161.JPG",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SixComp;
