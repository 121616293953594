import React from "react";
// import sideimg from "../../images/sideimg1.png";
import { NavLink } from "react-router-dom";

const Aboutsidecomp = () => {
  return (
    <div className="text-center">
      <h4
        className="py-3"
        style={{
          fontFamily: "Arapey, serif",
          fontWeight: "200",
          color: "#3b3939",
          fontSize: "20px",
        }}
      >
        ABOUT US
      </h4>
      <iframe
        src="https://www.youtube.com/embed/ocNvbMShYao?si=HKi3DALydGuHZlIm"
        width="300"
        height="200"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        title="Mission Video"
      ></iframe>
      {/* <p>Crisp. Clean. Different.</p> */}
      <p
        style={{
          fontFamily: "Lato, sans-serif",
          fontWeight: "200",
          color: "#696666",
          fontSize: "12px",
          // wordSpacing: "3px",
          padding:'15px'
        }}
        className="py-2"
      >
        CP has been documenting the best moments of life.
        Experience our passion, professionalism, and creativity on your special
        wedding day.
      </p>
      <button
        style={{
          width: "90%",
          padding: "5px 0",
          border: "none",
          backgroundColor: "rgb(154,204,154)",
          color: "white",
          fontWeight: "500",
        }}
      >
        REQUEST QUOTE
      </button>
      <p
        style={{
          fontFamily: "Lato, sans-serif",
          fontWeight: "200",
          color: "#696666",
          fontSize:'14x'
        }}
        className="py-3"
      >
        EXPLORE VENUES
      </p>
     <div>
        <NavLink className="pagelinks">Choice Production Venues</NavLink>
        <br />
        <NavLink className="pagelinks">Oberoi Udaivilas Venues</NavLink>
        <br />
        <NavLink className="pagelinks">Rambagh Palace Venues</NavLink>
        <br />
        <NavLink className="pagelinks">Leela Palace Venues</NavLink>
        <br/>
        <NavLink className="pagelinks">Udaipur Venues</NavLink>
        <br />
        <NavLink className="pagelinks">Goa Venues</NavLink> 
      </div>
      <div
        className="text-center my-3 py-3"
        style={{
          backgroundColor: "rgb(247,236,232)",
          width: "100%",
        }}
      >
        <img 
        // src={sideimg}
        src="https://s3.amazonaws.com/linandjirsablog/2019/12/17141622/wedding-resources-ljp-500x258.png"
         alt="img" style={{ width: "90%" }} />
        <h5
          style={{
            fontFamily: "Lato, sans-serif",
            fontWeight: "200",
            color: "#696666",
            fontSize:'16px'
          }}
          className="py-2"
        >
          JOIN OUR NEWSLETTER
        </h5>
        <p
          style={{
            fontFamily: "Lato, sans-serif",
            fontWeight: "200",
            color: "#696666",
            fontSize: "12px",
          }}
        >
          Subscribe to our monthly newsletter and instantly receive access to
          our library of resources, including:
        </p>
        <span style={{fontSize:'14px'}}>- Cultural Wedding Guides</span>
        <br />
        <span style={{fontSize:'14px'}}>- Best Engagement Photo Locations</span>
        <br />
        <span style={{fontSize:'14px'}}>- 12 Essential Wedding Planning Tips</span>
      </div>
    </div>
  );
};

export default Aboutsidecomp;
