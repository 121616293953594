import React, { useEffect, useState } from "react";
import axios from "axios";

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const allUsers = async () => {
    try {
      const { data } = await axios.get(
        "http://localhost:8081/api/auth/alluser"
      );
      setUsers(data?.users);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    allUsers();
  }, []);
  return (
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
          
          </div>
          <div className="col-md-9">All Users</div>
          {users.map((u) => (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{u.name}</td>
                  <td>{u.email}</td>
                  <td>{u.phone}</td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>
  );
};

export default AllUsers;
