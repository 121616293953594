import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const SevenComp = () => {
  const images = [
    {
      original: "/images/IMG_7460.JPG",
      thumbnail: "/images/IMG_7460.JPG",
    },
    {
      original: "/images/IMG_7462.JPG",
      thumbnail: "/images/IMG_7462.JPG",
    },
    {
      original: "/images/IMG_6780.JPG",
      thumbnail: "/images/IMG_6780.JPG",
    },
    {
      original: "/images/IMG_6646.JPG",
      thumbnail: "/images/IMG_6646.JPG",
    },
    {
      original: "/images/IMG_6459.JPG",
      thumbnail: "/images/IMG_6459.JPG",
    },
    {
      original: "/images/IMG_6650.JPG",
      thumbnail: "/images/IMG_6650.JPG",
    },
    {
      original: "/images/IMG_6742.JPG",
      thumbnail: "/images/IMG_6742.JPG",
    },
    {
      original: "/images/IMG_6854.JPG",
      thumbnail: "/images/IMG_6854.JPG",
    },
    {
      original: "/images/IMG_6890.JPG",
      thumbnail: "/images/IMG_6890.JPG",
    },
    {
      original: "/images/IMG_6874.JPG",
      thumbnail: "/images/IMG_6874.JPG",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 p-4">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SevenComp;
