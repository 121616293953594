import React from "react";
import AdminMenu from "../layout/AdminMenu";
import { useAuth } from "../context/auth";

const AdminDashboard = () => {
  const [auth,setAuth] = useAuth();
  return (
     <>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div>
            <div className="col-md-3">
              <AdminMenu />
            </div>
            <div className="col-md-9">
              <div className="card w-70 p-3">
                <h3>Admin Name : {auth?.user?.name}</h3>
                <h3>Admin Email : {auth?.user?.email}</h3>
                <h3>Admin Phone : {auth?.user?.phone}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
     </>
  );
};

export default AdminDashboard;
