import React from "react";
import Aboutsidecomp from "./Aboutsidecomp";
// import Aboutimg from "../../images/aboutimg.png";
import Footercontact from "../../layout/footercontact";
import ContactFix from "../../components/ContactFix";
import PreWeddingComp from "../../slideComp/PreWeddingComp";
import Layout from "../../layout/Layout";
import SixComp from "../../slideComp/SixComp";

const OurPhotograpgyteam = () => {
  const style1 = {
    backgroundImage: `url("https://www.linandjirsa.com/wp-content/uploads/PYE_9665-Edit.jpg")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "70vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <Layout title={"ChoiceProduction-OurStory"}>
        <ContactFix />
        <div className="container-fluid" style={style1}>
          <div
            className="row"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-sm-12">
              <h2
                style={{
                  fontSize: "4vw",
                  fontFamily: "Arapey, serif",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                CHOICE PRODUCTION PHOTOGRAPHY
              </h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 py-4">
              {/* <h1>CHOICE PRODUCTION WEDDING SHOOT</h1> */}
              <iframe
                src="https://www.youtube.com/embed/ocNvbMShYao?si=HKi3DALydGuHZlIm"
                frameborder="0"
                allow="autoplay"
                width={"100%"}
               
              />
              <div className="py-3 text-center">
                <h2>Best Pre-Wedding Photography</h2>
                <PreWeddingComp />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Aboutsidecomp />
            </div>
          </div>
        </div>
        <SixComp />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-4">
              <div>
                <img
                  src="/images/IMG_5617.JPG"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <Footercontact />
      </Layout>
    </>
  );
};

export default OurPhotograpgyteam;
