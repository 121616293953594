import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const FourComp = () => {
  const images = [
    {
      original: "/images/2G7A2529.JPG",
      thumbnail: "/images/2G7A2529.JPG",
    },
    {
      original: "/images/2G7A2640.JPG",
      thumbnail: "/images/2G7A2640.JPG",
    },
    {
      original: "/images/2G7A2739.JPG",
      thumbnail: "/images/2G7A2739.JPG",
    },
    {
      original: "/images/2G7A1948.JPG",
      thumbnail: "/images/2G7A1948.JPG",
    },
    {
      original: "/images/2G7A2085.JPG",
      thumbnail: "/images/2G7A2085.JPG",
    },
    {
      original: "/images/2G7A2121.JPG",
      thumbnail: "/images/2G7A2121.JPG",
    },
    {
      original: "/images/2G7A2498.JPG",
      thumbnail: "/images/2G7A2498.JPG",
    },
    {
      original: "/images/2G7A2661.JPG",
      thumbnail: "/images/2G7A2661.JPG",
    },
    {
      original: "/images/2G7A2504.JPG",
      thumbnail: "/images/2G7A2504.JPG",
    },
    {
      original: "/images/03.jpg",
      thumbnail: "/images/03.jpg",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FourComp;
