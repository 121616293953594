import React,{useState,useEffect} from 'react'
import ContactFix from '../../components/ContactFix';
import UdaipurComp from '../../slideComp/UdaipurComp';
import Footercontact from '../../layout/footercontact';
import Layout from '../../layout/Layout';
import SevenComp from '../../slideComp/SevenComp';
const Udaipur = () => {
    const style1 = {
        backgroundImage: `url("https://www.holidify.com/blog/wp-content/uploads/2014/09/oberoi_udaivilas.jpg")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "90vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
  return (
    <>
    <ContactFix/>
    <Layout title={"ChoiceProduction-WeddingVenues-Udaipur"}>
      <div className="container-fluid" style={style1}>
        <div
          className="row"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-sm-12">
            <h3
              style={{
                fontSize: "5vw",
                fontFamily: "Arapey, serif",
                color: "#fff",
                textAlign:'center'
              }}
            >
             Udaipur Wedding Shoot
            </h3>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
            <div className='col-lg-12'>
                <div>
                    <h2 style={{textAlign:"center"}}>Udaipur - For That Pretty Wedding Destination:</h2>
                    <p  style={{textAlign:"justify", fontSize:'14px'}}>This beautiful place is admired by Indians and foreigners alike owing to the lakes and palaces that exist through the city's expanse. The majestic palace buildings in Udaipur give it a sort of charisma that only a few cities can rival. They say, weddings in Udaipur seem like an extraordinary fairy-tale. One of the finest settings for a marriage ceremony is offered by the Jag Mandir palace or the Oberoi- UdaiVilas enclosing a touch of splendour, affluence and royalty. It easily makes it one of the most popular destinations of weddings in India. Remember Kalki Kochelin's Wedding From ?Yeh Jawaani Hai Diwani??</p>
                </div>
                <div>
                  <h2 className='text-center'>Bangoli Wedding Photography</h2>
                  <UdaipurComp/>
                  <h2 className='text-center'>VIVEK & ANJALI WEDDING VIDEOGRAPHY</h2>
                  <iframe width="100%" height="450" src="https://www.youtube.com/embed/RtoBMgEHK_s?si=j4tqIAWaQbQ0UA7X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
      </div>
      <SevenComp/>
      <Footercontact />
      </Layout>
    </>
  )
}

export default Udaipur