import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const JaipurComp = () => {
  const images = [
    {
      original: "/images/IMG_6379.JPG",
      thumbnail: "/images/IMG_6379.JPG",
    },
    {
      original: "/images/IMG_6469.JPG",
      thumbnail: "/images/IMG_6469.JPG",
    },
    {
      original: "/images/IMG_6383.JPG",
      thumbnail: "/images/IMG_6383.JPG",
    },
    {
      original: "/images/IMG_6164.JPG",
      thumbnail: "/images/IMG_6164.JPG",
    },
    {
      original: "/images/IMG_6201.JPG",
      thumbnail: "/images/IMG_6201.JPG",
    },
    {
      original: "/images/IMG_5566.JPG",
      thumbnail: "/images/IMG_5566.JPG",
    },
    {
      original: "/images/IMG_5673_1.JPG",
      thumbnail: "/images/IMG_5673_1.JPG",
    },
    {
      original: "/images/IMG_5698_1.JPG",
      thumbnail: "/images/IMG_5698_1.JPG",
    },
    {
      original: "/images/IMG_5872_1.JPG",
      thumbnail: "/images/IMG_5872_1.JPG",
    },
    {
      original: "/images/IMG_5688.JPG",
      thumbnail: "/images/IMG_5688.JPG",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <ImageGallery
            className="first"
            items={images}
            showPlayButton={true}
            showFullscreenButton={true}
            slideInterval={3000}
            slideOnThumbnailOver={true}
            showIndex={true}
            onPlay={() => {
              alert("slideshow is now playing!");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JaipurComp;
