import React from 'react'
import ContactFix from '../../components/ContactFix';
import JaipurComp from '../../slideComp/JaipurComp';
import Footercontact from '../../layout/footercontact';
import Layout from '../../layout/Layout';
import FiveComp from '../../slideComp/fiveComp';

const Jaipur = () => {
    const style1 = {
        backgroundImage: `url("https://www.holidify.com/blog/wp-content/uploads/2014/09/umaid_bhava_palace.jpg")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "90vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };

  return (
    <>
    <ContactFix/>
    <Layout title={"ChoiceProduction-WeddingVenues-Jaipur"}>
      <div className="container-fluid" style={style1}>
        <div
          className="row"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-sm-12">
            <h3
              style={{
                fontSize: "5vw",
                fontFamily: "Arapey, serif",
                color: "#fff",
                textAlign:'center'
              }}
            >
             Jaipur Wedding Shoot
            </h3>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
            <div className='col-lg-12'>
                <div>
                    <h2 style={{textAlign:"center"}}>Jaipur - For a Grand Wedding Location:</h2>
                    <p style={{textAlign:"justify",fontSize:'14px'}}>Jaipur offers a mélange of heritage and exquisiteness and thus it is so popular for grandiose marriage celebrations which usually take place in authentic flamboyant palaces or erstwhile mansions. The 'pink city' showcases a complete variety of festive ideas and venue options that extend from palaces like the Raj and Rambagh Palace to Havelis or traditional settings in the Samode Palace built into the Aravali Ranges. This is a destination wedding in India with a touch of royalty where the ambience adds to the Indian wedding decorations. </p>
                </div>
                 <div>
                  <JaipurComp/>
                  <h2 className='text-center'>LOKESH & MANU WEDDING VIDEOGRAPHY</h2>
                  <iframe width="100%" height="500" src="https://www.youtube.com/embed/3ZfasEx1Hio?si=nbGa1J_bMgbohaOK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                 </div>
            </div>
        </div>
      </div>
      <FiveComp/>
      <Footercontact />
      </Layout>
    </>
  )
}

export default Jaipur