import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import WeddingPhotography from "./pages/services/WeddingPhotography";
import OurPhotograpgyteam from "./pages/about/OurPhotographyteam";
import Contactus from "./pages/Contactus";
// import WeddingCinema from "./pages/services/WeddingCinema";
import Albums from "./pages/services/Albums";
import NeemranaFort from "./pages/weddingVenues/NeemranaFort";
import Udaipur from "./pages/weddingVenues/Udaipur";
import Jaipur from "./pages/weddingVenues/Jaipur";
import PreWedding from "./pages/services/PreWedding";
import PrivateRoute from "./routes/Private";
import AllUsers from "./admin/AllUsers";
import AdminDashoard from "./admin/AdminDashoard";
import Login from "./layout/login";
import get from 'lodash/get'


function App() {
  console.log(get({},'abc', 'abcd'));
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/weddingphotography" element={<WeddingPhotography />} />
        <Route path="/ourstory/OurPhotographyteam" element={<OurPhotograpgyteam />}/>
        <Route path="/weddingphotography" element={<WeddingPhotography />} />
        {/* <Route path="/weddingcinema" element={<WeddingCinema />} /> */}
        <Route path="/prewedding" element={<PreWedding />} />
        <Route path="/albums-flushmount" element={<Albums />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/about/OurPhotography-team" element={<OurPhotograpgyteam />} />
        <Route path="/NeemranaFort" element={<NeemranaFort />} />
        <Route path="/weddingvenues/Udaipur" element={<Udaipur />} />
        <Route path="/weddingvenues/Jaipur" element={<Jaipur />} />
        <Route path="/login" element={<Login/>}/>
        <Route path="/dashboard" element={<PrivateRoute/>}>
          <Route path="admin" element={<AdminDashoard/>}/>
          <Route path="user" element={<AllUsers/>}/> 
        </Route>
      </Routes>
    </>
  );
}

export default App;
