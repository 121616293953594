import React, { useState} from "react";
import "./home.css";
// import FirstComp from "../slideComp/FirstComp";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footercontact from "../layout/footercontact";
import ContactFix from "../components/ContactFix";
import Layout from "../layout/Layout";
import FourComp from "../slideComp/FourComp";
import FiveComp from "../slideComp/fiveComp"

const Home = () => {
  const [comp1, setComp1] = useState(true);
  const [comp2, setComp2] = useState(false);

  const image = [
    { img: "./images/feat_logo1.png" },
    { img: "./images/feat_logo2.png" },
    { img: "./images/feat_logo4.png" },
    { img: "./images/feat_logo7.png" },
    {
      img: "https://www.linandjirsa.com/wp-content/uploads/canon-feature-photography.png",
    },
    {
      img: "https://www.linandjirsa.com/wp-content/uploads/los-angeles-wedding-photography-huffington-post-1.png",
    },
  ];

  const image2 = [
    {
      img: "./images/IMG_1452.JPG",
      head: "Photo Editing Styles",
      para: "Every client has a unique style and vision for their wedding. The diversity of wedding venues and decor styles seamlessly matches various photo editing techniques. To accommodate these individual tastes, we offer three primary editing styles: Signature, Light and Airy, and Dark and Moody.",
    },
    {
      img: "./images/IMG_9384.JPG",
      head: "Wedding Videography",
      para: "We craft captivating, artistic narratives that celebrate meaningful moments. Rather than merely compiling events, our wedding videography cherishes your relationships—with one another, with your families, and with your dearest friends. It weaves a timeless story for future generations of your family to treasure and relive.",
    },
    {
      img: "./images/IMG_9391.jpg",
      head: "Favorite Wedding Vendors",
      para: "We've curated a list of professionals from Los Angeles and surrounding areas to help bring your wedding dreams to life. Selecting the ideal vendors is crucial for realizing your vision and ensuring your special day goes off without a hitch. Explore our top recommendations and don't hesitate to contact us with any inquiries.",
    },
  ];

  const style2 = {
    backgroundImage: `url("/images/YSH_2914.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "white",
    // height: "100vh",
  };

  const handleLeftArrowClick = () => {
    setComp1(false);
    setComp2(true);
  };

  const handleRightArrowClick = () => {
    setComp1(true);
    setComp2(false);
  };

  const Comp1 = () => {
    return (
      <>
        <div className="container-fluid">
          <div
            className="row pb-5"
            style={{
              backgroundImage: `url(/images/IMG_9557.JPG)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "#242424",
              height: "85vh",
            }}
          >
            <div className="col-sm-8 comp py-3 text-light">
              <h3 className="py-5">Featured Testimonials</h3>
              <p className="py-3 phtag">
              "You absolutely nailed what we had envisioned perfectly. The entire day we got to spend with you and your team, we had so much fun, you made both of us feel so comfortable. The final product of our engagement photos came out mind-blowing, my fiance was ecstatic, it was so nice to see her so happy with everything. (I was equally happy, but it made it even that much better to see her so happy)."
              </p>
              <FontAwesomeIcon
                onClick={handleLeftArrowClick}
                icon={faArrowLeft}
              />{" "}
              <br />
              01 / 02
              <br />
              <FontAwesomeIcon
                onClick={handleLeftArrowClick}
                icon={faArrowRight}
              />
            </div>
            {/* <div className="col-sm-4"></div> */}
          </div>
        </div>
      </>
    );
  };

  const Comp2 = () => {
    return (
      <>
        <div className="container-fluid">
          <div
            className="row pb-5"
            style={{
              backgroundImage: `url("/images/Copy of 138A8425.JPG")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "85vh",
            }}
          >
            <div className="col-sm-8 comp py-5 text-light">
              <h3 className="py-3">Featured Testimonials</h3>
              <p className="py-3 phtag">
                "I don’t know howwww you got the photos done so fast but THANK
                YOU I AM OBSESSED! perfection! So grateful."
              </p>
              <FontAwesomeIcon
                onClick={handleRightArrowClick}
                icon={faArrowLeft}
              />
              <br />
              02 / 02
              <br />
              <FontAwesomeIcon
                onClick={handleRightArrowClick}
                icon={faArrowRight}
              />
            </div>
            {/* <div className="col-sm-4"></div> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Layout title={"ChoiceProduction"}>
      <div className="video-container">
        <video
          style={{ width: "100%", height: "80vh" }}
          src="/images/vdo1.mp4"
          autoPlay
          loop
          muted
        />
      </div>

      <hr />
      <div className="container py-3">
        <div className="row">
          <div className="col-lg-12 logotext phtag">
            <p className="phtag">
              If you're seeking a Choice Production wedding photographer, look
              no further than Diwakar and Shikha. Since 2021, they have defined
              the pinnacle of creative wedding photography. Their style
              seamlessly blends wedding photojournalism with influences from
              fine-art and fashion photography, resulting in captivating and
              emotionally resonant imagery. Their work is characterized by a
              creative, clean, and crisp aesthetic that captures the romance,
              joy, and emotion of each moment. Diwakar and Shikha are more than
              just photographers; they are artists dedicated to crafting unique
              and expressive wedding memories.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#1a1a1a63" }}
        className="container py-3 text-center"
      >
        <p className="text-light">FEATURED IN:</p>
        <div className="row  ">
          <div className="col-sm-12">
            <div className="featmap">
              {image.map((e, index) => (
                <img key={index} alt="ahs" src={e.img} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ContactFix />
      <div className="container">
        <div className="row py-3">
          <div className="col-lg-4 brandmash2 text-center">
            <h6 style={{ letterSpacing: "1px" }}>
              TOP 100 WEDDING PHOTOGRAPHERS IN THE INDIAh
            </h6>
          </div>
          <div className="col-lg-4 brandmash2 text-center">
            <h6 style={{ letterSpacing: "1px" }}>LEGENDS OF LIGHT AWARD</h6>
          </div>
          <div className="col-lg-4 brandmash text-center">
            <h6 style={{ letterSpacing: "1px" }}>
              THE BEST WEDDING PHOTOGRAPHER IN CHOICE PRODUCTION
            </h6>
          </div>
        </div>
      </div>
      <FiveComp/>
      
      {/* <FirstComp /> */}
      <div style={style2} className="container-fluid p-3">
        <div className="row">
          <div className="col-lg-6 col-md-12 py-5">
            <h3 className="pt-3">Our Style and Approach</h3>
            <hr style={{ border: "1px solid white" }} />
            {/* <p className="py-2">CLEAN. CRISP. DIFFERENT</p> */}
            <p className="py-3 phtag">
              The four pillars of our style are 1) Celebrating Love, 2)
              Storytelling, 3) Creativity, and 4) Capturing Beauty. Each is
              equally important for a complete wedding photography experience.
              Learn more about our style below or browse some of our favorite
              photos below from our team of Los Angeles wedding photographers.
            </p>
            <button className="my-4 mybtn1">LEARN MORE ABOUT OUR STYLE</button>
          </div>
          {/* <div className="col-lg-6 col-md-12"></div> */}
        </div>
      </div>
      <div className="container-fluid py-3 text-center">
        <h4 className="pt-2">Our Resources</h4>
        <p className="py-2" style={{fontSize:'15px'}}>
          To help you plan for your wedding, see some of our most popular
          resources below.
        </p>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-around flex-wrap">
            {image2.map((e, index) => (
              <NavLink style={{ textDecoration: "none", cursor: "pointer" }}>
                <div className="map2" key={index}>
                  <img
                    style={{ width: "100%", height: "50%" }}
                    src={e.img}
                    alt="map"
                  />
                  <h4 className="py-3">{e.head}</h4>
                  <p className="phtag">{e.para}</p>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <button className="mybtn1">READ MORE PLANNING RESOURCES</button>
          </div>
        </div>
      </div>

      <FourComp/>

      {comp1 && <Comp1 />}
      {comp2 && <Comp2 />}

      <div className="container text-center">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="py-3">Khushal Teena Wedding Photography</h3>
            <p className="pb-2 phtag">
            We are Khushal & Teena, your dedicated Choice Production wedding photographer team. Rooted in the vibrant heart of Choice Production, we've embraced its diverse culture and breathtaking scenery. However, to me, 'home' transcends a mere location. It's the indescribable bond we share, a sanctuary of comfort, creativity, and connection. Being part of the journey as couples embark on their most significant adventure, witnessing the intimate moments that define their love, is a privilege I cherish deeply. My mission is to craft visual narratives that are as genuine and distinct as the love stories they depict.
            </p>
            <p className="pb-2 phtag">
            Inspired by Southern California's dynamic landscapes, from the serene beaches to the rugged mountains and the urban sprawl of Downtown LA, I find endless inspiration in our surroundings. Living in Choice Production offers me a unique opportunity to blend the natural with the urban, creating stunning backdrops for your love story. Photography is more than a service for me—it's preserving the fleeting moments that form the foundation of your memories. I am honored by the thought of capturing your wedding day, crafting the visual legacy of your love.
            </p>
            <p className="pb-2 phtag">
            If you're drawn to a passionate team of Choice Production wedding photographers ready to capture your love against the backdrop of California or anywhere your hearts take you, look no further. I invite you to connect with me, dive into my portfolio, and explore my blog for a glimpse into how I document love stories. In a city teeming with talent, I am grateful you see something special in my work. Let's create something unforgettable together.
            </p>
          </div>
        </div>
      </div>
      <Footercontact />
    </Layout>
    </>
  );
};

export default Home;
