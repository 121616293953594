import React, { useState, useEffect } from "react";
import axios from "axios";
import "./contact.css";
import Layout from "../layout/Layout";

const Contactus = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    role: "",
    description: "",
    date: "",
    eventType: "",
    social: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      const response = await axios.post(
        "http://localhost:8081/api/query/newquery",
        formData
      );
      console.log("Response:", response);

      setSuccessMessage("Query created successfully");
      setFormData({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        role: "",
        description: "",
        date: "",
        eventType: "",
        social: "",
      });
    } catch (error) {
      console.error("Failed to create query:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("Failed to create query. Please try again later.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Layout title={"ChoiceProduction-Contact"}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h4
              className="text-center pt-3"
              style={{
                fontFamily: "Arapey, serif",
                fontWeight: "400",
                fontSize: "20px",
                letterSpacing: "3px",
              }}
            >
              CONTACT US
            </h4>
            <p
              style={{
                fontWeight: "200",
                fontSize: "14px",
              }}
              className="text-center py-2"
            >
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Address
              </span>
              : A 89, Shivalik Colony, Malviya Nagar, New Delhi, Delhi 110017
              <br />
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Email
              </span>
              : studioshootbox@gmail.com
              <br />
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Phone
              </span>
              : 9627903849
            </p>
            <p
              style={{
                fontWeight: "200",
                fontSize: "14px",
              }}
              className="text-center py-2"
            >
              Welcome! We’re excited to work with you! Please fill out the form
              below, and our team will be in touch with the next steps!
            </p>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h4
                    className="text-center pt-3"
                    style={{
                      fontFamily: "Arapey, serif",
                      fontWeight: "400",
                      fontSize: "20px",
                      letterSpacing: "3px",
                    }}
                  >
                    CONTACT US
                  </h4>
                  <form className="form" onSubmit={handleSubmit}>
                    <label style={{ fontWeight: "200" }} className="my-1">
                      Your Role (Required)
                    </label>
                    <br />
                    <select
                      name="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      className="my-2"
                    >
                      <option value="select">select</option>
                      <option value="Bride">Bride</option>
                      <option value="Groom">Groom</option>
                      <option value="Planner">Planner</option>
                      <option value="Family">Family</option>
                      <option value="Other">Other</option>
                    </select>
                    <br />
                    <label style={{ fontWeight: "200" }} className="my-2">
                      Your Name (required)
                    </label>
                    <div className="row">
                      <div className="col-md-6 my-2">
                        <input
                          type="text"
                          name="fname"
                          value={formData.fname}
                          onChange={handleInputChange}
                          placeholder="First"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 my-2">
                        <input
                          type="text"
                          name="lname"
                          value={formData.lname}
                          onChange={handleInputChange}
                          placeholder="Last"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <label style={{ fontWeight: "200" }}>
                      Your Email Address (required)
                    </label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                    <br />
                    <label style={{ fontWeight: "200" }}>
                      Your Phone Number (required)
                    </label>
                    <br />
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                    <br />
                    <label style={{ fontWeight: "200" }}>
                      Event Date (in mm-dd-yyyy format only)
                    </label>
                    <br />
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                    <br />
                    <label style={{ fontWeight: "200" }}>Event Type</label>
                    <br />
                    <select
                      name="eventType"
                      value={formData.eventType}
                      onChange={handleInputChange}
                      className="form-control"
                    >
                      <option value="">-Please Choose an Option-</option>
                      <option value="Wedding">Wedding</option>
                      <option value="Engagement">Engagement</option>
                      <option value="Other">Other</option>
                    </select>
                    <br />
                    <label style={{ fontWeight: "200" }}>
                      How did you find us? (Required)
                    </label>
                    <br />
                    <select
                      name="social"
                      value={formData.social}
                      onChange={handleInputChange}
                      className="form-control"
                    >
                      <option value="">-Please Choose an Option-</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Google">Google</option>
                      <option value="Planner">Planner</option>
                      <option value="Referral from Family and Friend">
                        Referral from Family and Friend
                      </option>
                      <option value="Yelp">Yelp</option>
                      <option value="Venue">Venue</option>
                      <option value="Other">Other</option>
                    </select>
                    <br />
                    <label style={{ fontWeight: "200" }}>Your Message</label>
                    <br />
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                    <br />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={submitting}
                    >
                      {submitting ? "Submitting..." : "Send"}
                    </button>

                    {error && <p className="text-danger">{error}</p>}
                    {successMessage && (
                      <p className="text-success">{successMessage}</p>
                    )}
                  </form>
                  <p
                    style={{
                      fontWeight: "200",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    *If you are having issues with the form above, email us at
                    studioshootbox@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <p
              style={{
                fontWeight: "200",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              *If you are having issues with the form above, email us at
              studioshootbox@gmail.com
            </p>
            <div className="my-3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.338463736229!2d77.20399207416405!3d28.529544688752452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1f7f1757d1b%3A0xb2f4861cad8f4fb1!2s89%2C%20A%20Block%20Main%20Rd%2C%20Block%20A%2C%20Shivalik%20Colony%2C%20Malviya%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110017!5e0!3m2!1sen!2sin!4v1711186324075!5m2!1sen!2sin"
                width="100%"
                height="400"
                title="map"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      </Layout>
    </>
  );
};

export default Contactus;
